<template>
  <div class="error-main">
    <van-empty class="custom-image" :image="custom" description="抱歉排队尚未开启" />
  </div>
</template>

<script>
import { Button, Empty } from 'vant'

export default {
  name: 'NotFound',
  components: {
    [Empty.name]: Empty,
    [Button.name]: Button
  },
  data() {
    return {
      custom: require('../../assets/error.png')
    }
  }
}
</script>

<style scoped>
.error-main{
  width: 100%;
  height: calc(100vh)
}
/deep/ .van-empty__bottom{width: calc(100vw)}
/deep/ .van-empty__description{font-size: 18px;color: #8993A8}
.btn{
  width: 100%;
  float: left;
  margin-top: calc(10vh);
  height: 50px;
}
.bottom-button {
  width: 90%;
  margin-left: 5%;
  height: 50px;
}
.custom-image{
  margin-top: calc(20vh);
  width: 100%;
  float: left;
}
.custom-image /deep/ .van-empty__image {
  width: 210px;
  height: 130px;
}
</style>
